.innerElementOpenModal {
    position: absolute !important;
    left: 0;
}

.contentElementOpenModal {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.bodyElementOpenModal {
    height: 100%;
    overflow: hidden;
}

.currentPlanContent {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.hotspotPosition {
    cursor: pointer;
    position: absolute;
}

.child {
    position: relative;
}

.currentImageTag {
    /*height: 100%;*/
}

.hotspotImagePosition {
    display: block;
    margin-top: -50%;
    margin-left: -50%;
}

.overlayElementOpenModal {
    position: absolute !important;
}

.polygon {
    position: absolute;
    width: 100%;
    height: 100%;
}

.polygonNew {
    position: absolute;
}

.shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.minimap {
    position: absolute;
    top: 24px;
    right: 80px;
    z-index: 1;
}

.minimapSmallSize {
    width: 160px;
    height: 90px;

    @media (min-width: 75.01em) and (max-height: 47em) {
        width: 320px;
        height: 180px;
    }

    @media (max-width: 75em) {
        width: 160px;
        height: 90px;
    }

    @media (max-width: 48em) {
        width: 320px;
        height: 180px;
    }

    @media (max-width: 30em) {
        width: 188px;
        height: 105px;
        right: 60px;
    }
}

.minimapBigSize {
    /*width: 687px;*/
    height: 386px;

    @media (min-width: 75.01em) and (max-height: 47em) {
        /*width: 864px;*/
        height: 485px;
    }

    @media (max-width: 75em) {
        /*width: 687px;*/
        max-height: 320px;
        height: 386px;
    }

    @media (max-width: 48em) {
        /*width: 602px;*/
        height: 338px;
    }

    @media (max-width: 30em) {
        /*width: 252px;*/
        height: 182px;
        right: 60px;
    }
}

.minimapImage {
    width: 100%;
    height: 100%;
}

.hotspotImage {
    width: 24px;
}

.childMinimap {
    position: relative;
}

.polygonPointer {
    cursor: pointer;
}
